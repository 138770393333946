import {
  FlexBox,
  Grid,
  Loader,
  Section,
  SectionTitle,
} from 'jmt-styled-components'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { PageContainer } from '../components/base/layouts/PageContainer'
import { LinkBox } from '../components/dynamic-components/LinkBox'
import { UserProfile } from '../components/UserProfile'
import { fetchSummeryInfo } from '../state/app-info/app-info-actions'
import { useShallowEqualSelector } from '../utils/custom-hooks/useShallowEqualSelector'
import { useTranslation } from '../utils/custom-hooks/useTranslation'
import { PageTitle } from '../components/base/texts/PageTitle'

const GridSection = styled(Section)`
  label: CustomGridSection; 
  > div[class$='JMT-General-Grid'] {
    div {
      flex-grow: 1;
    }
  }
`

export const LandingPage = ({ title, blockedUserInfo }) => {
  const {
    summery,
    membership,
    fetching,
  } = useShallowEqualSelector((state) => state.appInfoReducer)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchSummeryInfo())
  }, [])

  const { t } = useTranslation()

  if (
    !fetching.summery
    || fetching.summery === 'fetching'
  ) {
    return (
      <PageContainer>
        <Loader />
      </PageContainer>
    )
  }

  if (!summery.PageMain) {
    return (
      <PageContainer>
        <div>
          We have an error please try again later
        </div>
      </PageContainer>
    )
  }

  const LinkBoxes = [
    ...summery.PageMain.BannerLinkBoxes.map((item) => ({
      ...item,
      size: 'full',
      ButtonIcon: '',
      imageLink: {
        url: item.ButtonLink.Url,
        target: item.ButtonLink.Target,
      },
    })),
    ...summery.PageMain.LinkBoxes.map((item) => ({ ...item, size: 'one-third' })),
  ]

  return (
    <PageContainer>
      <PageTitle>
        {t(`${title}_Page-Title`)}
      </PageTitle>
      <FlexBox>
        <UserProfile />
      </FlexBox>
      {
      membership.Blocked && blockedUserInfo != null
        ? (
          <div>
            {blockedUserInfo}
          </div>
        )
        : (
          <>
            <GridSection>
              <Grid>
                {
            LinkBoxes.map((box) => (
              <LinkBox
                key={box.Title}
                size={box.size}
                data={{
                  title: box.Title,
                  description: box.Description,
                  buttonTitle: box.ButtonTitle,
                  buttonIcon: box.ButtonIcon,
                  link: {
                    url: box.ButtonLink.Url,
                    target: box.ButtonLink.Target,
                  },
                  imageLink: {
                    url: box.ButtonLink.Url,
                    target: box.ButtonLink.Target,
                  },
                  image: box.ImageUrl,
                  modulePermissions: box.ModulePermissions,
                  redirectModal: box.RedirectModal,
                }}
              />
            ))
          }
              </Grid>
            </GridSection>
            <GridSection>
              <SectionTitle>
                {t('Landing_Page-Custom_Tiles-Title')}
              </SectionTitle>
              <Grid>
                {
                summery.PageMain.CustomTilesLinkBoxes.map((box) => (
                  <LinkBox
                    key={box.Title}
                    data={{
                      title: box.Title,
                      description: box.Description,
                      buttonTitle: box.ButtonTitle,
                      buttonIcon: box.ButtonIcon,
                      link: {
                        url: box.ButtonLink.Url,
                        target: box.ButtonLink.Target,
                      },
                      image: box.ImageUrl,
                    }}
                  />
                ))
              }
              </Grid>
            </GridSection>
          </>
        )
      }
    </PageContainer>
  )
}

LandingPage.propTypes = {
  title: PropTypes.string.isRequired,
  blockedUserInfo: PropTypes.string,
}

LandingPage.defaultProps = {
  blockedUserInfo: null,
}
