import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom'
import { Loader } from 'jmt-styled-components'

import { PageContainer } from '../../components/base/layouts/PageContainer'
import { useShallowEqualSelector } from '../../utils/custom-hooks/useShallowEqualSelector'
import { Newsletters } from './sub-routes/Newsletters'
import { MyProfileRoot } from './sub-routes/MyProfileRoot'
import { NotFound } from '../NotFound'
import { AreaOfInterest } from './sub-routes/AreaOfInterest'
import { DynamicRoutesWithAccessCheck } from '../../components/DynamicRoutesWithAccessCheck'
import { FurtherInformation } from './sub-routes/FurtherInformation'
import { BrevoNewsletter } from './sub-routes/BrevoNewsletter'
import { NewslettersRapidMail } from './sub-routes/NewslettersRapidMail'

const dynamicComponents = {
  Newsletters,
  AreaOfInterest,
  FurtherInformation,
  BrevoNewsletter,
  NewslettersRapidMail,
}

export const MyProfile = () => {
  const { path } = useRouteMatch()
  const { fetching } = useShallowEqualSelector((state) => state.appInfoReducer)
  const associationConfig = useShallowEqualSelector((state) => ({ ...state.moduleConfigReducer.associationConfig }))

  const { pages } = associationConfig
  const myProfileConfig = pages.find((page) => page.componentName === 'MyProfile')

  if (
    !fetching.membership
    || fetching.membership === 'fetching'
  ) {
    return (
      <PageContainer>
        <Loader />
      </PageContainer>
    )
  }

  const dynamicRoutesWithAccess = DynamicRoutesWithAccessCheck({
    routes: myProfileConfig.subRoutes,
    componentsSelection: dynamicComponents,
    leadingPath: path,
  })

  return (
    <Switch>
      <Route path={`${path}`} exact>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <MyProfileRoot {...myProfileConfig.props} subRoutes={myProfileConfig.subRoutes} />
      </Route>
      {dynamicRoutesWithAccess}
      <Route path={`${path}`}>
        <NotFound />
      </Route>
    </Switch>
  )
}

// this route is imported through react.lazy() which only supports default exports for now
export default MyProfile /* eslint-disable-line import/no-default-export */
