import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useShallowEqualSelector } from '../../utils/custom-hooks/useShallowEqualSelector'
import { accessPermissionEvaluation } from './access-permission-evaluation'

export const AccessPermissionChecker = ({
  accessRequirements,
  children,
}) => {
  const [isPermitted, setIsPermitted] = useState(accessRequirements ? 'loading' : 'permitted')
  const token = useShallowEqualSelector((state) => state.appInfoReducer.token)
  const membership = useShallowEqualSelector((state) => state.appInfoReducer.membership)

  useEffect(() => {
    if (!accessRequirements) {
      // not needed
    } else {
      accessPermissionEvaluation({
        accessRequirements,
        membershipInfo: membership,
      })
        .then((res) => {
          if (res) {
            setIsPermitted('permitted')
          } else {
            setIsPermitted('denied')
          }
        })
    }
  }, [membership, token, accessRequirements])

  return children(isPermitted)
}

AccessPermissionChecker.propTypes = {
  children: PropTypes.func.isRequired,
  accessRequirements: PropTypes.shape({
    context: PropTypes.string,
    shortName: PropTypes.string,
    termsConditionsId: PropTypes.number,
    moduleName: PropTypes.string,
  }),
}

AccessPermissionChecker.defaultProps = {
  accessRequirements: null,
}
