import {
  PageContainer,
  Centralizer,
  Loader,
} from 'jmt-styled-components'

export const LoadingRoute = () => (
  <PageContainer>
    <Centralizer>
      <Loader />
    </Centralizer>
  </PageContainer>
)
