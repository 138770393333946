import i18next from 'i18next'

import { ManageFetch } from '../../api/manage-fetch'
import { SET_MODULE_CONFIG } from './module-config-types'

export const setModuleConfig = (config) => (dispatch) => {
  ManageFetch.setInjectables({
    ...config,
  })
  dispatch({
    type: SET_MODULE_CONFIG,
    payload: {
      ...config,
    },
  })
}

export const fetchTranslationsAction = () => (dispatch, getState) => {
  ManageFetch.request`${(p) => p.apiUrl}/api/modules/GetResourcesForContext`({
    query: {
      context: (p) => p.context,
      language: (p) => p.language,
      setname: 'MyAssociation',
      shortname: (p) => p.short,
    },
  })
    .then((res) => {
      const { moduleConfigReducer } = getState()
      const parsed = res.data.reduce((acc, val) => {
        acc[val.resourcename] = val.resourcevalue
        return acc
      }, {})

      i18next.addResourceBundle(moduleConfigReducer.language, 'global',
        {
          'Newsletter_Page-Custom_Queries-Success_Text': 'You have Updated your subscription',
          ...parsed,
        })
      i18next.changeLanguage(moduleConfigReducer.language)
      dispatch({ type: SET_MODULE_CONFIG, payload: { isI18nLoaded: true } })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const fetchAssociationConfigAction = (onSuccess) => (dispatch) => {
  ManageFetch.request`${(p) => p.apiUrl}/v2/Membership/MyAssociationConfiguration`({
    query: {
      societyShort: (p) => p.short,
    },
  })
    .then((res) => {
      // TODO: remove when schema update is done
      console.log({ schema: res.data })

      dispatch({
        type: SET_MODULE_CONFIG,
        payload: {
          associationConfig: res.data,
        },
      })
      onSuccess()
    })
    .catch((err) => {
      console.log(err)
    })
}
