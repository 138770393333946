import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getUserFlags, setUserInfo } from '../state/user-info/user-info-actions'
import { useShallowEqualSelector } from '../utils/custom-hooks/useShallowEqualSelector'
import { useFlags } from '../utils/custom-hooks/use-getters/useFlags'
import { fetchMembershipInfo, fetchSummeryInfo } from '../state/app-info/app-info-actions'

export const AuthCatcher = ({ children }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isRedirect, setIsRedirect] = useState(false)
  const { oneTimeLoginRedirect } = useFlags()
  const { userInfoReducer } = useShallowEqualSelector((storeState) => storeState)
  const [userAuth, setUserAuth] = useState({ token: null, returnUrl: '/' })
  useEffect(() => {
    if (
      userInfoReducer.flags
      && userAuth.token
      && !isRedirect
    ) {
      const { returnUrl } = userAuth
      if (returnUrl[0] === '/') {
        if (returnUrl === '/') {
          if (
            oneTimeLoginRedirect != null
            && (
              userInfoReducer.flags?.oneTimeLoginRedirect == null
              || userInfoReducer.flags?.oneTimeLoginRedirect[oneTimeLoginRedirect.key] !== true
            )
          ) {
            history.push(oneTimeLoginRedirect.url)
          } else {
            history.push(returnUrl)
          }
        } else {
          history.push(returnUrl)
        }
      } else {
        window.location.href = returnUrl
      }
      setIsRedirect(true)
    }
  }, [userInfoReducer, userAuth])
  useEffect(() => {
    const onTokenSuccess = (token) => {
      dispatch(setUserInfo({ token }))
    }
    const catchLogin = (e) => {
      const { token, returnUrl } = e.detail
      onTokenSuccess(token)
      dispatch(getUserFlags())
      setUserAuth({ token, returnUrl })
      dispatch(fetchMembershipInfo())
      dispatch(fetchSummeryInfo())
    }
    const catchToken = (e) => {
      const { token } = e.detail
      onTokenSuccess(token)
    }
    window.addEventListener('m-anage.login', catchLogin)
    window.addEventListener('m-anage.found-token', catchToken)
    return () => {
      window.removeEventListener('m-anage.login', catchLogin)
      window.removeEventListener('m-anage.found-token', catchToken)
    }
  }, [])

  return children
}

AuthCatcher.propTypes = {
  children: PropTypes.node.isRequired,
}
