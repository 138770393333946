import { checkPermission } from '../../utils/checkPermission'

export const accessPermissionEvaluation = async ({
  accessRequirements,
  membershipInfo,
}) => {
  const rules = []

  if (accessRequirements == null) {
    return true
  }

  if (accessRequirements?.membership) {
    if (accessRequirements.membership?.status) {
      const idx = rules.push(false) - 1
      if (accessRequirements.membership.status === membershipInfo.Status) {
        rules[idx] = true
      }
    }
    if (accessRequirements.membership.blocked != null) {
      const idx = rules.push(false) - 1
      if (accessRequirements.membership.blocked === membershipInfo.Blocked) {
        rules[idx] = true
      }
    }
    if (accessRequirements.membership?.showInDirectory) {
      const idx = rules.push(false) - 1
      if (membershipInfo.ShowInDirectory) {
        rules[idx] = true
      }
    }
  }
  if (accessRequirements.remote) {
    const idx = rules.push(false) - 1
    if (accessRequirements.remote.type === 'default') {
      const {
        context,
        shortName,
        moduleName,
      } = accessRequirements.remote
      const res = await new Promise((resolve, reject) => {
        checkPermission({
          modulePermissions: {
            context,
            shortName,
            termsConditionsId: null,
            moduleName,
          },
          onDenied: reject,
          onPermitted: resolve,
        })
      })
        .then(() => true)
        .catch(() => false)
      rules[idx] = res
    }
  }
  return rules.every((item) => item)
}
